<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('authorization::modules.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('authorization::modules.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('authorization::modules.columns.translation_key').ucFirst() }}</label>
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('authorization::modules.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('authorization::modules.columns.description').ucFirst() }}</label>
                    <textarea
                        id="description"
                        v-model="model.description"
                        :class="{'border-danger': states.description === false}"
                        :placeholder="$t('authorization::modules.placeholders.description').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.description_translation_key === false}"
                        class="form-label"
                        for="description_translation_key"
                    >{{ $t('authorization::modules.columns.description_translation_key').ucFirst() }}</label>
                    <translation-input
                        v-model="model.description_translation_key"
                        :error="errors.description_translation_key"
                        :placeholder="$t('authorization::modules.placeholders.description_translation_key', model.id ? {attribute: model.description} : {}).ucFirst()"
                        :state="states.description_translation_key"
                    ></translation-input>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-10">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import TranslationInput from "../elements/translation-input.vue";

export default {
    name: "ModuleForm",

    emits: ['saved'],

    components: {TranslationInput},

    props: {
        module: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'module/loading',
            'states': 'module/states',
            'errors': 'module/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.module)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`module/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/authorization/modules/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        module: function () {
            this.model = JSON.parse(JSON.stringify(this.module))
        },
    },

    created() {
        // console.log(this.module)
    }
}
</script>

<style scoped>

</style>
